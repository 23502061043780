<template>
  <div class="about">
    <section class="text-gray-600 body-font">
      <div class="container px-5 py-24 mx-auto">
        <div
          class="flex flex-col text-center w-full mb-20"
          data-aos="zoom-out"
          data-aos-delay="150"
        >
          <h1 class="text-2xl font-medium title-font mb-4 text-gray-900">
            OUR TEAM
          </h1>
          <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
            We have experience working with large and small businesses and are
            ready to develop a targeted strategy and plan that’s just right for
            you.
          </p>
        </div>
        <div class="flex flex-wrap -m-4" data-aos="fade-up">
          <div
            class="
              p-4
              lg:w-1/4
              md:w-1/2
              transform
              transition
              duration-500
              hover:scale-110
            "
          >
            <div class="h-full flex flex-col items-center text-center">
              <img
                alt="team"
                class="
                  flex-shrink-0
                  rounded-lg
                  w-full
                  h-56
                  object-cover object-center
                  mb-4
                "
                :src="require('@/assets/team/umer.jpeg')"
              />
              <div class="w-full">
                <h2 class="title-font font-medium text-lg text-gray-900">
                  Muhammad Umer Farooq
                </h2>
                <h3 class="text-gray-500 mb-3">CEO & Lead Developer</h3>
                <p class="mb-4">
                  Umer is the founder, chairman and CEO of AlphaSoftHub
                  (Private) Limited. Umer is responsible for setting the overall
                  direction and product strategy for the company. He is also
                  responsible for the development role as lead developer..
                </p>
                <span class="inline-flex">
                  <a class="text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"
                      ></path>
                    </svg>
                  </a>
                  <a class="ml-2 text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"
                      ></path>
                    </svg>
                  </a>
                  <a class="ml-2 text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"
                      ></path>
                    </svg>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div
            class="
              p-4
              lg:w-1/4
              md:w-1/2
              transform
              transition
              duration-500
              hover:scale-110
            "
          >
            <div class="h-full flex flex-col items-center text-center">
              <img
                alt="team"
                class="
                  flex-shrink-0
                  rounded-lg
                  w-full
                  h-56
                  object-cover object-center
                  mb-4
                "
                :src="require('@/assets/team/hamza.jpg')"
              />
              <div class="w-full">
                <h2 class="title-font font-medium text-lg text-gray-900">
                  Muhammad Ameer Hamza
                </h2>
                <h3 class="text-gray-500 mb-3">Director & Developer</h3>
                <p class="mb-4">
                  Hamaz is the co-founder and Director of AlphaSoftHub (Private)
                  Limited. Hamza is responsible for the basic direction for the
                  company. He is also responsible for the development role as
                  Python and AI developer..
                </p>
                <span class="inline-flex">
                  <a class="text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"
                      ></path>
                    </svg>
                  </a>
                  <a class="ml-2 text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"
                      ></path>
                    </svg>
                  </a>
                  <a class="ml-2 text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"
                      ></path>
                    </svg>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div
            class="
              p-4
              lg:w-1/4
              md:w-1/2
              transform
              transition
              duration-500
              hover:scale-110
            "
          >
            <div class="h-full flex flex-col items-center text-center">
              <img
                alt="team"
                class="
                  flex-shrink-0
                  rounded-lg
                  w-full
                  h-56
                  object-cover object-center
                  mb-4
                "
                :src="require('@/assets/team/zain.jpg')"
              />
              <div class="w-full">
                <h2 class="title-font font-medium text-lg text-gray-900">
                  Zain Ul Abdin
                </h2>
                <h3 class="text-gray-500 mb-3">Manager & Backend Developer</h3>
                <p class="mb-4">
                  Zain is the manager of AlphaSoftHub (Private) Limited. He is
                  responsible for the development role as Backend Developer.
                </p>
                <span class="inline-flex">
                  <a class="text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"
                      ></path>
                    </svg>
                  </a>
                  <a class="ml-2 text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"
                      ></path>
                    </svg>
                  </a>
                  <a class="ml-2 text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"
                      ></path>
                    </svg>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div
            class="
              p-4
              lg:w-1/4
              md:w-1/2
              transform
              transition
              duration-500
              hover:scale-110
            "
          >
            <div class="h-full flex flex-col items-center text-center">
              <img
                alt="team"
                class="
                  flex-shrink-0
                  rounded-lg
                  w-full
                  h-56
                  object-cover object-center
                  mb-4
                "
                :src="require('@/assets/team/usman.jpg')"
              />
              <div class="w-full">
                <h2 class="title-font font-medium text-lg text-gray-900">
                  Muhammad Usman Naeem
                </h2>
                <h3 class="text-gray-500 mb-3">Backend Developer</h3>
                <p class="mb-4">
                  Usman is responsible for mainly backend development.
                </p>
                <span class="inline-flex">
                  <a class="text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"
                      ></path>
                    </svg>
                  </a>
                  <a class="ml-2 text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"
                      ></path>
                    </svg>
                  </a>
                  <a class="ml-2 text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"
                      ></path>
                    </svg>
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div
            class="
              p-4
              lg:w-1/4
              md:w-1/2
              transform
              transition
              duration-500
              hover:scale-110
            "
          >
            <div class="h-full flex flex-col items-center text-center">
              <img
                alt="team"
                class="
                  flex-shrink-0
                  rounded-lg
                  w-full
                  h-56
                  object-cover object-center
                  mb-4
                "
                :src="require('@/assets/team/sami.jpeg')"
              />
              <div class="w-full">
                <h2 class="title-font font-medium text-lg text-gray-900">
                  Abdul Sami
                </h2>
                <h3 class="text-gray-500 mb-3">Backend Developer</h3>
                <p class="mb-4">
                  Sami is responsible for mainly backend development.
                </p>
                <span class="inline-flex">
                  <a class="text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"
                      ></path>
                    </svg>
                  </a>
                  <a class="ml-2 text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"
                      ></path>
                    </svg>
                  </a>
                  <a class="ml-2 text-gray-500">
                    <svg
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"
                      ></path>
                    </svg>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="js">
export default {
  name: "team",
  data() {
    return {
      team: [
        {
          name: "Muhammad Umer Farooq",
          title: "CEO & Lead Developer",
                    avatar: require("@/assets/team/umer.jpeg"),
          description:
            "Umer is the founder, chairman and CEO of AlphaSoftHub (Private) Limited. Umer is responsible for setting the overall direction and product strategy for the company. He is also responsible for the development role as lead developer..",

        },
                {
          name: "Muhammad Ameer Hamza",
          title: "Director & Developer",
                    avatar: require("@/assets/team/umer.jpeg"),
          description:
            "Umer is the founder, chairman and CEO of AlphaSoftHub (Private) Limited. Umer is responsible for setting the overall direction and product strategy for the company. He is also responsible for the development role as lead developer..",

        },
      ]
    }
  }
}

</script>
